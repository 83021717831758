export default {
  namespaced: true,
  state: {
    message: {
      text: false,
      className: false,
    },
  },
  mutations: {
    unset(state) {
      Object.assign(state.message, { text: false });
      Object.assign(state.message, { className: false });
    },
    set(state, globalMessage) {
      Object.assign(state.message, { text: globalMessage.message });
      if (typeof globalMessage.className !== 'undefined') {
        Object.assign(state.message, { className: globalMessage.className });
      }
    },
  },
  actions: {
    set(context, payload) {
      context.commit('set', payload);
      if (typeof payload.timeout !== 'undefined') {
        setTimeout(() => {
          context.commit('unset');
        }, payload.timeout);
      }
    },
    unset(context) {
      context.commit('unset');
    },
  },
};
