<template>
  <span>
  <!--i v-if="!['users', 'clock', 'comment'].includes(icon)" :class="'fa ' + icon" aria-hidden="true"></i-->
  <!-- USERS -->
  <svg class="icon" v-if="icon === 'users'" :width="width" viewBox="0 0 15 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(14,0,0,14,-8.9407e-06,12)">
        <g id="Prancheta1">
            <path class="fill" d="M0.331,-0.357C0.302,-0.399 0.286,-0.449 0.286,-0.5C0.286,-0.512 0.287,-0.525 0.289,-0.537C0.265,-0.528 0.24,-0.524 0.214,-0.524C0.139,-0.524 0.081,-0.571 0.069,-0.571C-0.002,-0.571 0,-0.42 0,-0.374C0,-0.313 0.052,-0.286 0.108,-0.286L0.183,-0.286C0.22,-0.33 0.273,-0.355 0.331,-0.357ZM0.929,-0.002C0.929,-0.128 0.899,-0.321 0.735,-0.321C0.717,-0.321 0.647,-0.244 0.536,-0.244C0.424,-0.244 0.355,-0.321 0.336,-0.321C0.172,-0.321 0.143,-0.128 0.143,-0.002C0.143,0.089 0.203,0.143 0.292,0.143L0.78,0.143C0.869,0.143 0.929,0.089 0.929,-0.002ZM0.357,-0.714C0.357,-0.793 0.293,-0.857 0.214,-0.857C0.136,-0.857 0.071,-0.793 0.071,-0.714C0.071,-0.636 0.136,-0.571 0.214,-0.571C0.293,-0.571 0.357,-0.636 0.357,-0.714ZM0.75,-0.5C0.75,-0.618 0.654,-0.714 0.536,-0.714C0.417,-0.714 0.321,-0.618 0.321,-0.5C0.321,-0.382 0.417,-0.286 0.536,-0.286C0.654,-0.286 0.75,-0.382 0.75,-0.5ZM1.071,-0.374C1.071,-0.42 1.073,-0.571 1.002,-0.571C0.991,-0.571 0.932,-0.524 0.857,-0.524C0.831,-0.524 0.807,-0.528 0.783,-0.537C0.785,-0.525 0.786,-0.512 0.786,-0.5C0.786,-0.449 0.77,-0.399 0.741,-0.357C0.799,-0.355 0.851,-0.33 0.888,-0.286L0.963,-0.286C1.019,-0.286 1.071,-0.313 1.071,-0.374ZM1,-0.714C1,-0.793 0.936,-0.857 0.857,-0.857C0.778,-0.857 0.714,-0.793 0.714,-0.714C0.714,-0.636 0.778,-0.571 0.857,-0.571C0.936,-0.571 1,-0.636 1,-0.714Z" :style="'fill-rule:nonzero;fill:'+color+';'"/>
        </g>
    </g>
</svg>
  <!-- END USERS -->
  <!-- CLOCK -->
<svg class="icon" v-if="icon === 'clock'" :width="width" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(14,0,0,14,0.999993,12), translate(0,0.15)">
        <g id="Prancheta1">
            <path class="fill" d="M0.5,-0.554C0.5,-0.564 0.492,-0.571 0.482,-0.571L0.446,-0.571C0.436,-0.571 0.429,-0.564 0.429,-0.554L0.429,-0.357L0.304,-0.357C0.294,-0.357 0.286,-0.349 0.286,-0.339L0.286,-0.304C0.286,-0.294 0.294,-0.286 0.304,-0.286L0.482,-0.286C0.492,-0.286 0.5,-0.294 0.5,-0.304L0.5,-0.554ZM0.732,-0.357C0.732,-0.19 0.596,-0.054 0.429,-0.054C0.261,-0.054 0.125,-0.19 0.125,-0.357C0.125,-0.525 0.261,-0.661 0.429,-0.661C0.596,-0.661 0.732,-0.525 0.732,-0.357ZM0.857,-0.357C0.857,-0.594 0.665,-0.786 0.429,-0.786C0.192,-0.786 0,-0.594 0,-0.357C0,-0.121 0.192,0.071 0.429,0.071C0.665,0.071 0.857,-0.121 0.857,-0.357Z" :style="'fill-rule:nonzero;fill:'+color+';'"/>
        </g>
    </g>
</svg>
  <!-- END CLOCK -->
  <!-- comment -->

<svg class="icon" v-if="icon === 'comment'" :width="width" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(14,0,0,14,0.499992,11), translate(0,0.15)">
        <g id="Prancheta1">
            <path class="fill" d="M0.5,-0.643C0.732,-0.643 0.929,-0.512 0.929,-0.357C0.929,-0.202 0.732,-0.071 0.5,-0.071C0.476,-0.071 0.451,-0.073 0.427,-0.076L0.396,-0.079L0.372,-0.058C0.326,-0.018 0.275,0.014 0.218,0.037C0.233,0.012 0.247,-0.019 0.257,-0.059L0.272,-0.112L0.224,-0.14C0.127,-0.195 0.071,-0.274 0.071,-0.357C0.071,-0.512 0.268,-0.643 0.5,-0.643ZM1,-0.357C1,-0.555 0.776,-0.714 0.5,-0.714C0.224,-0.714 0,-0.555 0,-0.357C0,-0.244 0.074,-0.143 0.189,-0.078C0.162,0.016 0.122,0.049 0.087,0.089C0.079,0.099 0.069,0.107 0.072,0.121L0.072,0.122C0.075,0.134 0.085,0.143 0.096,0.143L0.099,0.143C0.121,0.14 0.142,0.136 0.162,0.131C0.259,0.106 0.346,0.06 0.419,-0.004C0.445,-0.002 0.473,-0 0.5,-0C0.776,-0 1,-0.16 1,-0.357Z" :style="'fill-rule:nonzero; fill:'+color+';'"/>
        </g>
    </g>
</svg>
<!-- END COMMENT -->
<!-- SHOPPING BAG -->
<svg v-if="icon === 'shoppingBag'" class="icon" :width="width" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g id="Prancheta1" transform="matrix(0.312508,0,0,0.313763,-26.0219,-19.0892)">
        <clipPath id="_clip1">
            <rect x="83.268" y="60.84" width="44.799" height="44.62"/>
        </clipPath>
        <g clip-path="url(#_clip1)">
            <g transform="matrix(44.7989,0,0,44.6197,83.2774,99.0852)">
                <path class="fill" d="M0.98,-0.071L0.02,-0.071L0,0.103C-0.001,0.113 0.002,0.123 0.009,0.131C0.016,0.138 0.026,0.143 0.036,0.143L0.964,0.143C0.974,0.143 0.984,0.138 0.991,0.131C0.998,0.123 1.001,0.113 1,0.103L0.98,-0.071ZM0.929,-0.54C0.926,-0.557 0.911,-0.571 0.893,-0.571L0.75,-0.571L0.75,-0.5C0.75,-0.46 0.718,-0.429 0.679,-0.429C0.639,-0.429 0.607,-0.46 0.607,-0.5L0.607,-0.571L0.393,-0.571L0.393,-0.5C0.393,-0.46 0.361,-0.429 0.321,-0.429C0.282,-0.429 0.25,-0.46 0.25,-0.5L0.25,-0.571L0.107,-0.571C0.089,-0.571 0.074,-0.557 0.071,-0.54L0.023,-0.107L0.977,-0.107L0.929,-0.54ZM0.714,-0.643C0.714,-0.761 0.618,-0.857 0.5,-0.857C0.382,-0.857 0.286,-0.761 0.286,-0.643L0.286,-0.5C0.286,-0.48 0.302,-0.464 0.321,-0.464C0.341,-0.464 0.357,-0.48 0.357,-0.5L0.357,-0.643C0.357,-0.722 0.421,-0.786 0.5,-0.786C0.579,-0.786 0.643,-0.722 0.643,-0.643L0.643,-0.5C0.643,-0.48 0.659,-0.464 0.679,-0.464C0.698,-0.464 0.714,-0.48 0.714,-0.5L0.714,-0.643Z" :style="'fill-rule:nonzero; fill:'+color+';'"/>
            </g>
        </g>
    </g>
</svg>
<!-- END SHOPPING BAG -->
<!-- ASTERISK -->
<svg v-if="icon === 'asterisk'" class="icon" :width="width" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g id="Prancheta1" transform="matrix(0.312508,0,0,0.313763,-26.0219,-19.0892)">
        <g transform="matrix(44.7989,0,0,44.6197,84.8678,99.0852)">
            <path class="fill" d="M0.827,-0.271L0.679,-0.357L0.827,-0.443C0.861,-0.463 0.873,-0.507 0.853,-0.541L0.818,-0.602C0.798,-0.636 0.754,-0.648 0.72,-0.628L0.571,-0.543L0.571,-0.714C0.571,-0.753 0.539,-0.786 0.5,-0.786L0.429,-0.786C0.39,-0.786 0.357,-0.753 0.357,-0.714L0.357,-0.543L0.209,-0.628C0.175,-0.648 0.131,-0.636 0.111,-0.602L0.075,-0.541C0.056,-0.507 0.068,-0.463 0.102,-0.443L0.25,-0.357L0.102,-0.271C0.068,-0.252 0.056,-0.208 0.075,-0.174L0.111,-0.112C0.131,-0.078 0.175,-0.066 0.209,-0.086L0.357,-0.171L0.357,-0C0.357,0.039 0.39,0.071 0.429,0.071L0.5,0.071C0.539,0.071 0.571,0.039 0.571,-0L0.571,-0.171L0.72,-0.086C0.754,-0.066 0.798,-0.078 0.818,-0.112L0.853,-0.174C0.873,-0.208 0.861,-0.252 0.827,-0.271Z" :style="'fill-rule:nonzero; fill:'+color+';'"/>
        </g>
    </g>
</svg>
<!-- END ASTERISK -->
<!-- CALENDAR -->

<svg v-if="icon === 'calendar'" class="icon" :width="width" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g id="Prancheta1" transform="matrix(0.312508,0,0,0.313763,-26.0219,-19.0892)">
        <clipPath id="_clip1">
            <rect x="83.268" y="60.84" width="44.799" height="44.62"/>
        </clipPath>
        <g clip-path="url(#_clip1)">
            <g transform="matrix(44.7989,0,0,44.6197,84.8678,99.0852)">
                <path class="fill" d="M0.071,0.071L0.071,-0.089L0.232,-0.089L0.232,0.071L0.071,0.071ZM0.268,0.071L0.268,-0.089L0.446,-0.089L0.446,0.071L0.268,0.071ZM0.071,-0.125L0.071,-0.304L0.232,-0.304L0.232,-0.125L0.071,-0.125ZM0.268,-0.125L0.268,-0.304L0.446,-0.304L0.446,-0.125L0.268,-0.125ZM0.071,-0.339L0.071,-0.5L0.232,-0.5L0.232,-0.339L0.071,-0.339ZM0.482,0.071L0.482,-0.089L0.661,-0.089L0.661,0.071L0.482,0.071ZM0.268,-0.339L0.268,-0.5L0.446,-0.5L0.446,-0.339L0.268,-0.339ZM0.696,0.071L0.696,-0.089L0.857,-0.089L0.857,0.071L0.696,0.071ZM0.482,-0.125L0.482,-0.304L0.661,-0.304L0.661,-0.125L0.482,-0.125ZM0.286,-0.607C0.286,-0.598 0.277,-0.589 0.268,-0.589L0.232,-0.589C0.223,-0.589 0.214,-0.598 0.214,-0.607L0.214,-0.768C0.214,-0.777 0.223,-0.786 0.232,-0.786L0.268,-0.786C0.277,-0.786 0.286,-0.777 0.286,-0.768L0.286,-0.607ZM0.696,-0.125L0.696,-0.304L0.857,-0.304L0.857,-0.125L0.696,-0.125ZM0.482,-0.339L0.482,-0.5L0.661,-0.5L0.661,-0.339L0.482,-0.339ZM0.696,-0.339L0.696,-0.5L0.857,-0.5L0.857,-0.339L0.696,-0.339ZM0.714,-0.607C0.714,-0.598 0.706,-0.589 0.696,-0.589L0.661,-0.589C0.651,-0.589 0.643,-0.598 0.643,-0.607L0.643,-0.768C0.643,-0.777 0.651,-0.786 0.661,-0.786L0.696,-0.786C0.706,-0.786 0.714,-0.777 0.714,-0.768L0.714,-0.607ZM0.929,-0.643C0.929,-0.682 0.896,-0.714 0.857,-0.714L0.786,-0.714L0.786,-0.768C0.786,-0.817 0.746,-0.857 0.696,-0.857L0.661,-0.857C0.612,-0.857 0.571,-0.817 0.571,-0.768L0.571,-0.714L0.357,-0.714L0.357,-0.768C0.357,-0.817 0.317,-0.857 0.268,-0.857L0.232,-0.857C0.183,-0.857 0.143,-0.817 0.143,-0.768L0.143,-0.714L0.071,-0.714C0.032,-0.714 0,-0.682 0,-0.643L0,0.071C0,0.11 0.032,0.143 0.071,0.143L0.857,0.143C0.896,0.143 0.929,0.11 0.929,0.071L0.929,-0.643Z" :style="'fill-rule:nonzero; fill:'+color+';'"/>
            </g>
        </g>
    </g>
</svg>
<!-- END CALENDAR -->
<!-- CLOSE -->

<svg class="icon closeIcon" v-if="icon === 'close'" :width="width" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g id="Prancheta1" transform="matrix(0.263458,0,0,0.293572,-49.5212,-49.4651)">
        <rect x="187.966" y="168.494" width="53.139" height="47.688" style="fill:none;"/>
        <g transform="matrix(53.1393,0,0,47.6885,193.659,207.667)">
            <path class="fill" d="M0.724,-0.119C0.724,-0.133 0.719,-0.147 0.709,-0.157L0.545,-0.321L0.709,-0.485C0.719,-0.496 0.724,-0.509 0.724,-0.523C0.724,-0.537 0.719,-0.551 0.709,-0.561L0.633,-0.637C0.623,-0.647 0.609,-0.653 0.595,-0.653C0.581,-0.653 0.567,-0.647 0.557,-0.637L0.393,-0.473L0.229,-0.637C0.219,-0.647 0.205,-0.653 0.191,-0.653C0.177,-0.653 0.163,-0.647 0.153,-0.637L0.077,-0.561C0.067,-0.551 0.061,-0.537 0.061,-0.523C0.061,-0.509 0.067,-0.496 0.077,-0.485L0.241,-0.321L0.077,-0.157C0.067,-0.147 0.061,-0.133 0.061,-0.119C0.061,-0.105 0.067,-0.092 0.077,-0.081L0.153,-0.006C0.163,0.004 0.177,0.01 0.191,0.01C0.205,0.01 0.219,0.004 0.229,-0.006L0.393,-0.17L0.557,-0.006C0.567,0.004 0.581,0.01 0.595,0.01C0.609,0.01 0.623,0.004 0.633,-0.006L0.709,-0.081C0.719,-0.092 0.724,-0.105 0.724,-0.119Z" :style="'fill-rule:nonzero; fill:'+color+';'"/>
        </g>
    </g>
</svg>
<!-- END CLOSE -->
<!-- INFO -->
<svg class="icon" v-if="icon === 'info'" :width="width" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g id="Prancheta1" transform="matrix(0.263458,0,0,0.293572,-49.5212,-49.4651)">
        <rect x="187.966" y="168.494" width="53.139" height="47.688" style="fill:none;"/>
        <g transform="matrix(53.1393,0,0,47.6885,205.046,211.073)">
            <path class="fill" d="M0.357,-0.107C0.357,-0.127 0.341,-0.143 0.321,-0.143L0.286,-0.143L0.286,-0.464C0.286,-0.484 0.27,-0.5 0.25,-0.5L0.036,-0.5C0.016,-0.5 0,-0.484 0,-0.464L0,-0.393C0,-0.373 0.016,-0.357 0.036,-0.357L0.071,-0.357L0.071,-0.143L0.036,-0.143C0.016,-0.143 0,-0.127 0,-0.107L0,-0.036C0,-0.016 0.016,-0 0.036,-0L0.321,-0C0.341,-0 0.357,-0.016 0.357,-0.036L0.357,-0.107ZM0.286,-0.75C0.286,-0.77 0.27,-0.786 0.25,-0.786L0.107,-0.786C0.088,-0.786 0.071,-0.77 0.071,-0.75L0.071,-0.643C0.071,-0.623 0.088,-0.607 0.107,-0.607L0.25,-0.607C0.27,-0.607 0.286,-0.623 0.286,-0.643L0.286,-0.75Z" :style="'fill-rule:nonzero; fill:'+color+';'"/>
        </g>
    </g>
</svg>
<!--END INFO-->
<!-- TRASH -->
<svg class="icon" v-if="icon === 'trash'" :width="width" viewBox="0 0 14 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g id="Prancheta1" transform="matrix(0.312508,0,0,0.341824,-26.0219,-20.7965)">
        <clipPath id="_clip1">
            <rect x="83.268" y="60.84" width="44.799" height="44.62"/>
        </clipPath>
        <g clip-path="url(#_clip1)">
            <g transform="matrix(57.0167,0,0,52.1267,83.2679,101.736)">
                <path class="fill" d="M0.286,-0.446C0.286,-0.456 0.278,-0.464 0.268,-0.464L0.232,-0.464C0.222,-0.464 0.214,-0.456 0.214,-0.446L0.214,-0.125C0.214,-0.115 0.222,-0.107 0.232,-0.107L0.268,-0.107C0.278,-0.107 0.286,-0.115 0.286,-0.125L0.286,-0.446ZM0.429,-0.446C0.429,-0.456 0.421,-0.464 0.411,-0.464L0.375,-0.464C0.365,-0.464 0.357,-0.456 0.357,-0.446L0.357,-0.125C0.357,-0.115 0.365,-0.107 0.375,-0.107L0.411,-0.107C0.421,-0.107 0.429,-0.115 0.429,-0.125L0.429,-0.446ZM0.571,-0.446C0.571,-0.456 0.564,-0.464 0.554,-0.464L0.518,-0.464C0.508,-0.464 0.5,-0.456 0.5,-0.446L0.5,-0.125C0.5,-0.115 0.508,-0.107 0.518,-0.107L0.554,-0.107C0.564,-0.107 0.571,-0.115 0.571,-0.125L0.571,-0.446ZM0.643,-0.042C0.643,-0.016 0.628,-0 0.625,-0L0.161,-0C0.158,-0 0.143,-0.016 0.143,-0.042L0.143,-0.571L0.643,-0.571L0.643,-0.042ZM0.268,-0.643L0.295,-0.708C0.297,-0.71 0.301,-0.714 0.305,-0.714L0.482,-0.714C0.484,-0.714 0.489,-0.71 0.491,-0.708L0.518,-0.643L0.268,-0.643ZM0.786,-0.625C0.786,-0.635 0.778,-0.643 0.768,-0.643L0.595,-0.643L0.556,-0.736C0.545,-0.763 0.512,-0.786 0.482,-0.786L0.304,-0.786C0.274,-0.786 0.241,-0.763 0.229,-0.736L0.19,-0.643L0.018,-0.643C0.008,-0.643 0,-0.635 0,-0.625L0,-0.589C0,-0.579 0.008,-0.571 0.018,-0.571L0.071,-0.571L0.071,-0.04C0.071,0.021 0.112,0.071 0.161,0.071L0.625,0.071C0.674,0.071 0.714,0.019 0.714,-0.042L0.714,-0.571L0.768,-0.571C0.778,-0.571 0.786,-0.579 0.786,-0.589L0.786,-0.625Z" :style="'fill-rule:nonzero; fill:'+color+';'"/>
            </g>
        </g>
    </g>
</svg>
<!-- TRASH END-->
  <!--ANGLE UP-->
<svg class="icon" v-if="icon === 'angleUp'" :width="width" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(14,0,0,14,2.49999,11.375)">
        <path d="M0.6,-0.196C0.6,-0.201 0.598,-0.206 0.594,-0.209L0.334,-0.469C0.331,-0.473 0.326,-0.475 0.321,-0.475C0.317,-0.475 0.312,-0.473 0.309,-0.469L0.049,-0.209C0.045,-0.206 0.043,-0.201 0.043,-0.196C0.043,-0.192 0.045,-0.187 0.049,-0.184L0.076,-0.156C0.08,-0.152 0.085,-0.15 0.089,-0.15C0.094,-0.15 0.099,-0.152 0.102,-0.156L0.321,-0.375L0.541,-0.156C0.544,-0.152 0.549,-0.15 0.554,-0.15C0.559,-0.15 0.563,-0.152 0.566,-0.156L0.594,-0.184C0.598,-0.187 0.6,-0.192 0.6,-0.196Z" :style="'fill-rule:nonzero; fill:'+color+';'"/>
    </g>
</svg>
  <!-- END ANGLE UP -->
  <!-- ANGLE DOWN -->
<svg class="icon angleDown" v-if="icon === 'angleDown'" :width="width" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(14,0,0,14,2.49999,11.625)">
        <path class="fill" d="M0.6,-0.446C0.6,-0.451 0.598,-0.456 0.594,-0.459L0.566,-0.487C0.563,-0.491 0.558,-0.493 0.554,-0.493C0.549,-0.493 0.544,-0.491 0.541,-0.487L0.321,-0.268L0.102,-0.487C0.099,-0.491 0.094,-0.493 0.089,-0.493C0.084,-0.493 0.08,-0.491 0.076,-0.487L0.049,-0.459C0.045,-0.456 0.043,-0.451 0.043,-0.446C0.043,-0.442 0.045,-0.437 0.049,-0.434L0.309,-0.174C0.312,-0.17 0.317,-0.168 0.321,-0.168C0.326,-0.168 0.331,-0.17 0.334,-0.174L0.594,-0.434C0.598,-0.437 0.6,-0.442 0.6,-0.446Z" :style="'fill-rule:nonzero; fill:'+color+';'"/>
    </g>
</svg>
  <!-- END ANGLE DOWN -->
<!--CIRCLE NOTCH -->
<svg v-if="icon === 'circleNotch'" :width="width" class="icon spins circleNotch" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:10;">
    <path class="stroke" d="M6.977,2.196C6.924,2.196 6.872,2.216 6.82,2.226C4.084,2.784 2.107,5.193 2.107,8C2.107,11.253 4.747,13.893 8,13.893C11.253,13.893 13.893,11.253 13.893,8C13.893,5.183 11.901,2.766 9.149,2.22C9.107,2.212 9.066,2.196 9.023,2.196" :style="'fill:none;stroke:'+color+';stroke-width: 1.5px;'"/>
</svg>
<!-- END CIRCLE NOTCH -->

<!-- EXTERNAL LINK -->
<svg class="icon" v-if="icon === 'externalLink'" :width="width" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(14,0,0,14,-8.34465e-06,13), scale(0.8)">
        <path class="fill" d="M0.786,-0.339C0.786,-0.349 0.778,-0.357 0.768,-0.357L0.732,-0.357C0.722,-0.357 0.714,-0.349 0.714,-0.339L0.714,-0.161C0.714,-0.112 0.674,-0.071 0.625,-0.071L0.161,-0.071C0.112,-0.071 0.071,-0.112 0.071,-0.161L0.071,-0.625C0.071,-0.674 0.112,-0.714 0.161,-0.714L0.554,-0.714C0.564,-0.714 0.571,-0.722 0.571,-0.732L0.571,-0.768C0.571,-0.778 0.564,-0.786 0.554,-0.786L0.161,-0.786C0.072,-0.786 0,-0.714 0,-0.625L0,-0.161C0,-0.072 0.072,-0 0.161,-0L0.625,-0C0.714,-0 0.786,-0.072 0.786,-0.161L0.786,-0.339ZM1,-0.821C1,-0.841 0.984,-0.857 0.964,-0.857L0.679,-0.857C0.659,-0.857 0.643,-0.841 0.643,-0.821C0.643,-0.812 0.647,-0.803 0.653,-0.796L0.752,-0.698L0.388,-0.334C0.384,-0.331 0.382,-0.326 0.382,-0.321C0.382,-0.317 0.384,-0.312 0.388,-0.309L0.451,-0.245C0.455,-0.242 0.46,-0.239 0.464,-0.239C0.469,-0.239 0.474,-0.242 0.477,-0.245L0.841,-0.609L0.939,-0.511C0.946,-0.504 0.955,-0.5 0.964,-0.5C0.984,-0.5 1,-0.516 1,-0.536L1,-0.821Z" :style="'fill-rule:nonzero; fill:'+color+';'"/>
    </g>
</svg>
<!-- EXTERNAL LINK -->
<!-- CHEVRON LEFT -->
<svg v-if="icon === 'chevronLeft'" class="icon" :width="width" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(14,0,0,14,4.375,11.5)">
        <path class="fill" d="M0.35,-0.554C0.35,-0.559 0.348,-0.563 0.344,-0.566L0.316,-0.594C0.313,-0.598 0.308,-0.6 0.304,-0.6C0.299,-0.6 0.294,-0.598 0.291,-0.594L0.031,-0.334C0.027,-0.331 0.025,-0.326 0.025,-0.321C0.025,-0.317 0.027,-0.312 0.031,-0.309L0.291,-0.049C0.294,-0.045 0.299,-0.043 0.304,-0.043C0.308,-0.043 0.313,-0.045 0.316,-0.049L0.344,-0.076C0.348,-0.08 0.35,-0.085 0.35,-0.089C0.35,-0.094 0.348,-0.099 0.344,-0.102L0.125,-0.321L0.344,-0.541C0.348,-0.544 0.35,-0.549 0.35,-0.554Z" :style="'fill-rule:nonzero; fill:'+color+';'"/>
    </g>
</svg>
<!-- END CHEVRON LEFT -->
<!-- CHEVRON RIGHT -->
<svg v-if="icon === 'chevronRight'" class="icon" :width="width" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(-14,1.71451e-15,-1.71451e-15,-14,9.625,2.49999)">
        <path class="fill" d="M0.35,-0.554C0.35,-0.559 0.348,-0.563 0.344,-0.566L0.316,-0.594C0.313,-0.598 0.308,-0.6 0.304,-0.6C0.299,-0.6 0.294,-0.598 0.291,-0.594L0.031,-0.334C0.027,-0.331 0.025,-0.326 0.025,-0.321C0.025,-0.317 0.027,-0.312 0.031,-0.309L0.291,-0.049C0.294,-0.045 0.299,-0.043 0.304,-0.043C0.308,-0.043 0.313,-0.045 0.316,-0.049L0.344,-0.076C0.348,-0.08 0.35,-0.085 0.35,-0.089C0.35,-0.094 0.348,-0.099 0.344,-0.102L0.125,-0.321L0.344,-0.541C0.348,-0.544 0.35,-0.549 0.35,-0.554Z" :style="'fill-rule:nonzero; fill:'+color+';'"/>
    </g>
</svg>
<!-- END CHEVRON RIGHT -->

<!-- START MUSEUM -->
<svg v-if="icon === 'museum'" viewBox="0 0 14 14" :width="width" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(0.0273442,0,0,0.0273442,0.000106753,-0.000124567)">
        <path class="fill" d="M240.1,4.216C249.1,-1.405 262,-1.405 271.9,4.216L443.6,102.4L447.1,104L447.1,104.9L495.9,132.2C508.5,139.4 514.6,154.2 510.9,168.2C507.2,182.2 494.5,192 479.1,192L31.1,192C17.49,192 4.795,182.2 1.071,168.2C-2.653,154.2 3.524,139.4 16.12,132.2L63.1,104.9L63.1,104L68.37,102.4L240.1,4.216ZM64,224L128,224L128,416L168,416L168,224L232,224L232,416L280,416L280,224L344,224L344,416L384,416L384,224L448,224L448,420.3C448.6,420.6 449.2,420.1 449.8,421.4L497.8,453.4C509.5,461.2 514.7,475.8 510.6,489.3C506.5,502.8 494.1,512 480,512L32,512C17.9,512 5.46,502.8 1.373,489.3C-2.713,475.8 2.517,461.2 14.25,453.4L62.25,421.4C62.82,420.1 63.41,420.6 64,420.3L64,224Z" :style="'fill-rule:nonzero; fill:'+color+';'"/>
    </g>
</svg>

<!-- END MUSEUM -->
  <!-- SOCIAL MEDIA TWITTER -->
<svg  v-if="icon === 'twitter'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" :width="width" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g transform="matrix(23.7371,0,0,23.7371,-29.7664,-28.8455)">
        <path class="fill" d="M18.244,2.25L21.552,2.25L14.325,10.51L22.827,21.75L16.17,21.75L10.956,14.933L4.99,21.75L1.68,21.75L9.41,12.915L1.254,2.25L8.08,2.25L12.793,8.481L18.244,2.25ZM17.083,19.77L18.916,19.77L7.084,4.126L5.117,4.126L17.083,19.77Z" :style="'fill-rule:nonzero; fill:'+color+';'"/>
    </g>
</svg>
  <!-- SOCIAL MEDIA TWITTER END -->

  <!-- SOCIAL MEDIA youtube -->
  <svg v-if="icon === 'youtube'" xmlns="http://www.w3.org/2000/svg" :width="width" viewBox="0 0 576 512"><path class="fill" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" :style="'fill-rule:nonzero; fill:'+color+';'"/></svg>

  <!-- END SOCIAL MEDIA youtube -->

  <!-- SOCIAL MEDIA Instagram -->
  <svg v-if="icon === 'instagram'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" :width="width"><path class="fill" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" :style="'fill-rule:nonzero; fill:'+color+';'"/></svg>
  <!-- END SOCIAL MEDIA Instagram -->

  <svg v-if="icon === 'facebook'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" :width="width"><path class="fill" d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" :style="'fill-rule:nonzero; fill:'+color+';'"/></svg>

<svg v-if="icon === 'linkedin'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" :width="width"><path class="fill" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" :style="'fill-rule:nonzero; fill:'+color+';'"/></svg>

<svg v-if="icon === 'tripadvisor'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 812.08 521.44" :width="width"><title>Ollie_Logo_RGB</title><path class="fill" d="M419,445.82a72,72,0,1,1-72-72A72,72,0,0,1,419,445.82Zm333.82-72a72,72,0,1,0,72,72A72,72,0,0,0,752.86,373.84Zm203,72c0,112.05-90.92,202.89-203,202.89a202.11,202.11,0,0,1-137.85-54L550,665.4,485,594.63a202.12,202.12,0,0,1-137.89,54.08c-112,0-202.89-90.84-202.89-202.89a202.35,202.35,0,0,1,66.18-149.95L144,223.63H291.52a459.76,459.76,0,0,1,517.34,0H956l-66.39,72.24A202.35,202.35,0,0,1,955.83,445.82Zm-471.46,0A137.31,137.31,0,1,0,347.06,583.13,137.3,137.3,0,0,0,484.37,445.82Zm218-205a396.23,396.23,0,0,0-304.77,0C484.28,274,550,351.5,550,441.86,550,351.51,615.71,274,702.37,240.83Zm187.8,205A137.31,137.31,0,1,0,752.86,583.13,137.31,137.31,0,0,0,890.17,445.82Z" transform="translate(-143.96 -143.96)" :style="'fill-rule:nonzero; fill:'+color+';'"/></svg>

<svg v-if="icon === 'tiktok'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" :width="width"><path class="fill" d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z" :style="'fill-rule:nonzero; fill:'+color+';'"/></svg>

<svg v-if="icon === 'bars'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" :width="width"><path class="fill"  d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" :style="'fill:'+color+';'"/></svg>

    </span>

</template>

<script>

export default {
  name: 'IconComponent',
  props: {
    icon: { default: 'question-circle-o' },
    width: { default: '16px' },
    color: { default: 'black' },
  },
};
</script>

<style lang="scss" scoped>

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.spins {
  animation: spin 2s infinite linear;
}
</style>
