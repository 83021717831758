export default {
  namespaced: true,
  state: {
    loaded: false,
    data: [],
  },
  mutations: {
    set(state, payload) {
      Object.assign(state, { loaded: true });
      const newSalutations = [];
      payload.data.forEach((salutation) => {
        if (salutation !== '') {
          const s = salutation;
          newSalutations.push({ name: s });
        }
      });
      Object.assign(state, { data: newSalutations });
    },
  },
  actions: {
    set(context, data) {
      context.commit('set', { data });
      context.dispatch('user/setFormValue', { salutation: { index: 0, name: '' } }, { root: true });
      context.dispatch('user/setSalutationFromCustomerData', null, { root: true });
    },
  },
};
