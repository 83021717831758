import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import { createI18n } from 'vue-i18n'
import translations from './translations/translations'
import Toasted from "@hoppscotch/vue-toasted"
import "@hoppscotch/vue-toasted/style.css"
import SmoothReflow from './components/SmoothReflow.vue'

const app = createApp(App)

const i18n = createI18n({
  locale: 'en',
  messages: translations
})


app.use(router)
app.use(store)
app.use(i18n)
app.use(Toasted)
app.component('SmoothReflow', SmoothReflow)

app.config.globalProperties.$filters = {
  truncate(text, stop, clamp) {
    return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
  }
}

window.vue = app.mount('#app')


const scripts = document.getElementsByTagName('script');
const lastScript = scripts[scripts.length - 1];
const publicURL = new URL(lastScript.src);
window.visitate = {};
window.visitate.webshopSrc = `${publicURL.protocol}//${publicURL.host}/`;


i18n.global.locale = window.vue.$store.state.language
