export default {
  conformToFormat(errors) {
    const newErrors = {};
    Object.keys(errors).forEach((key) => {
      if (typeof errors[key] === 'string') {
        newErrors[key] = [errors[key]];
      } else {
        newErrors[key] = errors[key];
      }
    });
    return newErrors;
  },
};
