import request from '../request';
import analytics from '../analytics';

let form = [];
const rawData = sessionStorage.getItem('cart');
if (rawData !== null && rawData !== 'undefined') {
  form = JSON.parse(rawData);
}

function totalWithoutVouchersLocal(state) {
  let total = 0;
  let sc = parseInt(state.shippingCosts, 10);
  if (Number.isNaN(sc)) {
    sc = 0;
  }
  state.form.forEach((item) => {
    if (item.type !== 'voucherCode') {
      total += parseInt(item.totalPrice, 10);
    }
  });
  total += sc;
  if (total < 0) {
    total = 0;
  }
  return total;
}

function prepareCart(cart) {
  const newCart = [];
  cart.forEach((item) => {
    const newItem = {};
    newItem.type = item.type;
    newItem.id = item.id;
    if (item.type === 'guidedTour') {
      newItem.datetime = `${item.date} ${item.time}`;
      if (!newItem.single_ticketing) {
        newItem.count = 1;
      }
    }
    if (item.type === 'merchandise') {
      newItem.count = item.count;
    }
    if (item.type === 'ticket') {
      newItem.count = item.totalCount;
      newItem.datetime = item.datetime;
    }
    if (item.type === 'dayTicket') {
      newItem.dayTickets.forEach((dayTicket) => {
        newItem.datetime = item.datetime;
        newCart.push({
          type: 'dayticket',
          count: dayTicket.count,
          id: dayTicket.id,
        });
      });
    }
    if (item.type !== 'dayTicket') {
      newCart.push(newItem);
    }
  });
  return newCart;
}

export default {
  namespaced: true,
  state: {
    form,
    requestInvoice: true, // for dms is set to false on load
    error: '',
    cartId: null,
    shippingCosts: null,
    shippingError: '',
    shippingCostsError: '',
    externalDocumentNumber: '',
  },
  mutations: {
    add(state, item) {
      let shouldAdd = true;
      if (item.type === 'voucherCode') {
        const existingItem = state.form.find((i) => item.barcode === i.barcode);
        if (typeof existingItem !== 'undefined') {
          shouldAdd = false;
        }
      }
      if (shouldAdd) {
        const newCart = state.form;
        newCart.push(item);
        sessionStorage.setItem('cart', JSON.stringify(state.form));
      }
    },
    calculateVoucherValues(state) {
      const total = totalWithoutVouchersLocal(state);
      let currentTotal = total;
      state.form.forEach((item, i) => {
        if (item.type === 'voucherCode') {
          const discount = item.totalPrice * -1;
          let remainingAmount = discount;
          let newTotal = currentTotal;
          if (currentTotal !== 0) {
            newTotal = currentTotal - discount;
            if (newTotal < 0) {
              newTotal = 0;
            }
            remainingAmount = discount - (currentTotal - newTotal);
          }
          state.form[i].remaining = remainingAmount;
          state.form[i].discount = (discount - remainingAmount) * -1;
          currentTotal = newTotal;
        }
      });
    },
    remove(state, index) {
      state.form.splice(index, 1);
      sessionStorage.setItem('cart', JSON.stringify(state.form));
    },
    clear(state) {
      state.form = [];
      state.cartId = null;
    },
    clearShippingError(state) {
      state.shippingCostsError = '';
    },
    setError(state, error) {
      let e = error;
      if (typeof error === 'object') {
        const keys = Object.keys(error);
        e = error[keys[0]];
      }
      state.error = e;
    },
    setCartId(state, cartId) {
      state.cartId = cartId;
    },
    setDocumentNumber(state, v) {
      state.externalDocumentNumber = v;
    },
    setShippingCost(state, shippingCosts) {
      Object.assign(state, { shippingCosts });
    },
    setShippingCostError(state, error) {
      state.shippingCostsError = error.code;
    },
    setRequestInvoice(state, v) {
      Object.assign(state, { requestInvoice: v });
    },
  },
  actions: {
    add(context, item) {
      context.commit('add', item);
    },
    clear(context) {
      context.commit('clear');
      sessionStorage.removeItem('cart');
    },
    clearShippingError(context) {
      context.commit('clearShippingError');
    },
    remove(context, index) {
      return new Promise((resolve) => {
        if (context.state.form[index].type === 'ticket') {
          analytics.removeTicketFromCart(context.state.form[index]);
        }
        context.commit('remove', index);
        resolve();
      });
    },
    setError(context, error) {
      context.commit('setError', error);
    },
    setCartId(context, cartId) {
      return new Promise((resolve) => {
        context.commit('setCartId', cartId);
        resolve();
      });
    },
    setRequestInvoice(context, v) {
      context.commit('setRequestInvoice', v);
    },
    setDocumentNumber(context, v) {
      context.commit('setDocumentNumber', v);
    },
    calculateShipping(context) {
      return new Promise((resolve) => {
        context.commit('clearShippingError');
        const { app } = context.rootState;
        let shouldCalculate = false;
        context.state.form.find((item) => {
          if (item.type === 'merchandise') {
            shouldCalculate = true;
            return true;
          }
          return false;
        });
        let canCalculateShipping = false;
        let data = {};
        let url = '';
        if (typeof context.rootState.user.data.jwt !== 'undefined') {
          if (context.rootState.user.data.jwt !== '') {
            canCalculateShipping = true;
            url = `${app.API_URL}${context.rootState.language}/${app.endpoints.SHIPPING_COSTS}?_=${context.rootState.user.data.jwt}`;
            data = {
              cart: prepareCart(context.state.form),
            };
          }
        }
        if (!canCalculateShipping && context.rootState.user.form.country.key && context.rootState.user.form.country.key !== '') {
          canCalculateShipping = true;
          url = `${app.API_URL}${context.rootState.language}/${app.endpoints.SHIPPING_COSTS}`;
          data = {
            country: context.rootState.user.form.country.key,
            cart: prepareCart(context.state.form),
          };
        } else if (!canCalculateShipping && typeof window.sessionStorage.customer !== 'undefined') {
          canCalculateShipping = true;
          url = `${app.API_URL}${context.rootState.language}/${app.endpoints.SHIPPING_COSTS}`;
          const costumer = JSON.parse(window.sessionStorage.customer);
          const { country } = costumer;
          if (country === '') {
            canCalculateShipping = false;
          }
          data = {
            country,
            cart: prepareCart(context.state.form),
          };
        }
        const type = 'POST';
        if (!(shouldCalculate && canCalculateShipping)) {
          context.commit('setShippingCost', null);
        } else {
          request.make({
            url, data, type,
          }, (err, res) => {
            if (err === null) {
              context.commit('setShippingCost', res.body.data);
              resolve();
            } else {
              context.commit('setShippingCost', null);
              context.commit('setShippingCostError', res.body);
              resolve();
            }
          });
        }
        if (!canCalculateShipping) {
          context.commit('setShippingCost', null);
        }
      });
    },
    calculateVoucherValues(context) {
      context.commit('calculateVoucherValues');
    },
  },
  getters: {
    total(state) {
      let total = 0;
      let sc = parseInt(state.shippingCosts, 10);
      if (Number.isNaN(sc)) {
        sc = 0;
      }
      state.form.forEach((item) => {
        total += parseInt(item.totalPrice, 10);
      });
      total += sc;
      if (total < 0) {
        total = 0;
      }
      return total;
    },
    totalWithoutVouchers(state) {
      return totalWithoutVouchersLocal(state);
    },
    thereIsAVoucher(state) {
      const v = state.form.find((i) => i.type === 'voucherCode');
      if (typeof v !== 'undefined') {
        return true;
      }
      return false;
    },
    thereAreRealItemsInCart(state) {
      const v = state.form.find((i) => i.type !== 'voucherCode');
      if (typeof v !== 'undefined') {
        return true;
      }
      return false;
    },
    paypalCart(state, getters) {
      const total = (getters.total / 100).toFixed(2);
      const subTotal = (getters.total / 100).toFixed(2);
      const payment = {
        transactions: [{
          amount: {
            total,
            currency: 'EUR',
            details: {
              subtotal: subTotal,
              tax: '0',
              shipping: '0',
              handling_fee: '0',
              shipping_discount: '0',
              insurance: '0',
            },
          },
          item_list: { items: [] },
        }],
      };

      payment.transactions[0].item_list = { items: [] };
      state.form.forEach((item) => {
        let quantity = 0;
        if (typeof item.totalCount !== 'undefined') {
          quantity = item.totalCount.toString();
        }
        if (item.type === 'merchandise') {
          quantity = item.count.toString();
        }
        payment.transactions[0].item_list.items.push({
          name: item.name,
          quantity,
          price: (item.totalPrice / 100).toFixed(2),
          tax: '0.00',
          currency: 'EUR',
        });
      });
      return payment;
    },
  },
};
