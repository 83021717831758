<template>
  <router-link class="link" :to="{name: 'checkout', params: {lang: language}}" v-on:click="clicked()">
    <Icon icon="shoppingBag" class="cartIcon" v-bind:class="{notEmpty: (count !== 0)}"></Icon>
    <span class="number">{{count}}</span>
  </router-link>
</template>

<script>
import { mapState } from 'vuex';
import Icon from '../Icon.vue';

export default {
  name: 'BasketComponent',
  emits: ['click'],
  components: {
    Icon,
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
      count: (state) => state.cart.form.length,
    }),

  },
  methods: {
    clicked() {
      this.$emit('click');
    },
  },
  watch: {

  },
};
</script>

<style lang="scss" scoped>
  .cartIcon {
    display: inline-block;
    margin-right: $sp;
  }

  .cartIcon, .number {
    color: $navbar-default-link-color;
  }
  .cartIcon.notEmpty {
    text-shadow: white 0 0 10px;
  }
  .link:hover {
    text-decoration: none;
    span {
      text-decoration: underline;
    }
  }
  .link.router-link-active:hover {
    text-decoration: none;
    font-weight: 300;
    cursor: default;
  }

</style>
