export default function (suffix) {
  const siteName = import.meta.env.VITE_SITE_NAME;
  let separator = '-';
  if (['bo', 'devbo'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
    separator = '|';
  }
  let title = '';
  if (suffix === '' || typeof suffix === 'undefined') {
    title = siteName;
  } else {
    title = `${siteName} ${separator} ${suffix}`;
  }
  return title;
}
