import request from '../request';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    categories: {},
    data: {},
    order: [],
    form: {
      quantity: 1,
      price: 0,
    },
  },
  mutations: {
    set(state, payload) {
      const order = Object.keys(payload);
      order.sort(
        (a, b) => parseInt(payload[a].sequence_no, 10) - parseInt(payload[b].sequence_no, 10),
      );
      state.data = payload;
      state.order = order;
    },
    setLoading(state, isLoading) {
      Object.assign(state, { isLoading });
    },
    setSingle(state, payload) {
      const keys = Object.keys(payload);
      if (typeof state.data[payload.id] === 'undefined') {
        state.data[payload.id] = {};
      }
      keys.forEach((key) => {
        state.data[payload.id][key] = payload[key];
      });
    },
    setCategories(state, categories) {
      const c = {};
      Object.keys(categories).forEach((categoryId) => {
        c[categoryId] = {
          id: categoryId,
          name: categories[categoryId],
          selected: false,
        };
      });
      state.categories = c;
    },
    setQuantity(state, quantity) {
      Object.assign(state.form, { quantity });
    },
    resetForm(state) {
      Object.assign(state.form, { quantity: 1 });
    },
    setCategoryFilter(state, payload) {
      const categoryId = Object.keys(payload)[0];
      const val = payload[categoryId];
      Object.assign(state.categories[categoryId], { selected: val });
    },
    setPrice(state, v) {
      let newV = v;
      if (v === '') {
        newV = state.form.price;
      }
      if (v < 0) {
        newV = v * -1;
      }
      state.form.price = newV;
    },
  },
  actions: {
    setPrice(context, v) {
      context.commit('setPrice', v);
    },
    resetForm(context) {
      context.commit('resetForm');
    },
    get(context, data) {
      return new Promise((resolve) => {
        context.commit('setLoading', true);
        const { app } = context.rootState;
        request.make({
          type: 'GET',
          url: `${app.API_URL}${context.rootState.language}/${app.endpoints.VOUCHER_LIST}`,
          data,
        }, (err, res) => {
          if (res.status === 200) {
            if (typeof res.body !== 'undefined') {
              context.commit('set', res.body.data);
              resolve();
            }
          }
          context.commit('setLoading', false);
        });
      });
    },
    getSingle(context, id) {
      return new Promise((resolve) => {
        context.commit('setLoading', true);
        const { app } = context.rootState;
        request.make({
          type: 'GET',
          url: `${app.API_URL}${context.rootState.language}/${app.endpoints.VOUCHER_SINGLE}/${id}`,
        }, (err, res) => {
          context.commit('setLoading', false);
          if (res.status === 200) {
            if (typeof res.body !== 'undefined') {
              context.commit('setSingle', res.body.data);
            }
          }
          resolve();
        });
      });
    },
    getCategories(context) {
      return new Promise((resolve) => {
        const { app } = context.rootState;
        request.make({
          type: 'GET',
          url: `${app.API_URL}${context.rootState.language}/${app.endpoints.VOUCHER_CATEGORY_LIST}`,
        }, (err, res) => {
          if (res.status === 200) {
            if (typeof res.body !== 'undefined') {
              if (typeof res.body.data !== 'undefined') {
                if (typeof res.body.data.group !== 'undefined') {
                  context.commit('setCategories', res.body.data.group);
                  resolve();
                }
              }
            }
          }
        });
      });
    },
    setCategoryFilter(context, payload) {
      context.commit('setCategoryFilter', payload);
    },
    setQuantity(context, quantity) {
      context.commit('setQuantity', quantity);
    },
    addToCart(context, id) {
      return new Promise((resolve) => {
        const voucher = context.state.data[id];
        let vat = 0;
        if (typeof voucher.vat_value !== 'undefined') {
          vat = parseFloat(voucher.vat_value);
        }
        const item = {};
        item.id = parseInt(voucher.id, 10);
        item.name = voucher.name;
        item.type = 'voucher';
        item.vat_value = vat;
        if (parseInt(voucher.variable_amount, 10) === 0) {
          item.count = context.state.form.quantity;
          item.price = parseInt(voucher.price, 10);
          item.totalPrice = voucher.price * context.state.form.quantity;
        } else {
          item.count = 1;
          item.price = parseInt(parseFloat(context.state.form.price) * 100, 10);
          item.totalPrice = item.price;
        }
        context.dispatch('cart/add', item, { root: true });
        context.dispatch('resetForm');
        resolve();
      });
    },
  },
  getters: {
    filteredList: (state) => {
      const list = {};
      if (Object.keys(state.categories).length > 0) {
        const enabledCategoriesCount = Object.keys(state.categories)
          .filter((categoryKey) => state.categories[categoryKey].selected).length;
        Object.keys(state.data).forEach((key) => {
          let shouldAdd = true;
          const category = state.data[key].groupId;
          if (category !== null && typeof state.categories[category] !== 'undefined') {
            if (!state.categories[category].selected && enabledCategoriesCount > 0) {
              shouldAdd = false;
            }
          } else if (enabledCategoriesCount > 0) {
            shouldAdd = false;
          }
          if (shouldAdd) {
            list[key] = state.data[key];
          }
        });
        return list;
      }
      return state.data;
    },
  },
};
