import request from '../request';

export default {
  namespaced: true,
  state: {
    data: {},
  },
  mutations: {
    set(state, payload) {
      if (typeof payload.social_media === 'undefined') {
        return;
      }
      const socialLinks = [];
      Object.keys(payload.social_media).forEach((k) => {
        socialLinks.push({ name: k, url: payload.social_media[k].link });
      });

      socialLinks.sort((a, b) => parseInt(payload.social_media[a.name].sequence_no, 10) - parseInt(payload.social_media[b.name].sequence_no, 10));
      state.data.socialLinks = socialLinks;
    },
  },
  actions: {
    get(context) {
      return new Promise((resolve, reject) => {
        const { app } = context.rootState;
        const url = `${app.API_URL}${context.rootState.language}/${app.endpoints.STATIC_CONTENT}`;
        const type = 'GET';
        request.make({
          url, type,
        }, (reqErr, res) => {
          if (res.status === 200 && typeof res.body.data !== 'undefined') {
            context.commit('set', res.body.data);
            resolve();
          }
          reject();
        });
      });
    },
  },
};
