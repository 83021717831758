<template>
  <div class="CookieDisclaimer" v-if="!cookiesAccepted && (showCookieDisclaimer=== 'true' || showCookieDisclaimer=== true)">
    <div class="message" v-html="message"></div>
    <div class="actions">
      <button v-on:click="acceptCookies">{{$t('COOKIE_ACCEPT')}}</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CookieDisclaimer',
  computed: {
    ...mapState({
      message: (state) => state.cookieDisclaimer.disclaimer,
      cookiesAccepted: (state) => state.cookieDisclaimer.cookiesAccepted,
      showCookieDisclaimer: (state) => state.app.showCookieDisclaimer,
    }),
  },
  methods: {
    ...mapActions({
      getDisclaimer: 'cookieDisclaimer/get',
      acceptCookies: 'cookieDisclaimer/accept',
    }),
  },

  mounted() {
    if (this.showCookieDisclaimer === 'true' || this.showCookieDisclaimer === true) {
      this.getDisclaimer();
    }
  },
};

</script>

<style lang="scss" scoped>
.CookieDisclaimer {
  display: flex;
  align-items: center;

  background:$secondary-block-color-1;
  color: $header-color;
  padding: $sp;
  .message {
    flex-grow: 1;
  }
  .actions {
    padding-left: $sp;
    button {
      min-width: 120px;
    }
  }
}

</style>
