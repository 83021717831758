import request from '../request';

export default {
  namespaced: true,
  state: {
    data: {},
  },
  mutations: {
    set(state, payload) {
      state.data = payload;
    },
  },
  actions: {
    get(context) {
      return new Promise((resolve, reject) => {
        const { app } = context.rootState;
        const url = `${app.API_URL}${context.rootState.language}/${app.endpoints.STATIC_CONTENT}`;
        const type = 'GET';
        request.make({
          url, type,
        }, (reqErr, res) => {
          if (res.status === 200 && typeof res.body.data !== 'undefined') {
            context.commit('set', res.body.data.meta);
            resolve();
          }
          reject();
        });
      });
    },
  },

};
