let loadedModule; //eslint-disable-line
const nm = () => import('./nm.js')
const fvv = () => import('./fvv.js')
const gh = () => import('./gh.js')
const max = () => import('./max.js')
const khb = () => import('./khb.js')
const noCustom = () => import('./noCustom.js')


if (['nm', 'devnm'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
    loadedModule = nm;
} else if (['fvv', 'devfvv'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
    loadedModule = fvv
} else if (['gh', 'devgh'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
    loadedModule = gh
} else if (['max', 'devmax'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
    loadedModule = max
} else if (['khb', 'devkhb'].includes(import.meta.env.VITE_SITE_FRONT_ID)) {
    loadedModule = khb
} else {
    loadedModule = noCustom
}

export default loadedModule;

