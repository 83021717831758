import request from '../request';

export default {
  namespaced: true,
  state: {
    loaded: false,
    data: {},
  },
  mutations: {
    set(state, data) {
      Object.assign(state, { data });
      Object.assign(state, { loaded: true });
    },
  },
  actions: {
    get(context) {
      return new Promise((resolve) => {
        const { app } = context.rootState;
        request.make({
          type: 'GET',
          url: `${app.API_URL}${context.rootState.language}/${app.endpoints.STATIC_CONTENT}`,
        }, (err, res) => {
          if (res.status === 200) {
            context.commit('set', res.body.data);
          }
          resolve();
        });
      });
    },
  },
};
