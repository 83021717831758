
let formG = {
  name: null,
  street: null,
  city: null,
  zip: null,
  country: {
    index: null,
    name: null,
    key: null,
  },
};
let sameAsBillingG = true;

const persistentShippingString = window.sessionStorage.getItem('shipping');
if (persistentShippingString !== null) {
  formG = JSON.parse(persistentShippingString);
  sameAsBillingG = false;
}

export default {
  namespaced: true,
  state: {
    sameAsBilling: sameAsBillingG,
    isEditingShipping: false,
    form: formG,
    errors: {},
  },
  mutations: {
    setSameAsBilling(state, sameAsBilling) {
      Object.assign(state, { sameAsBilling });
    },
    setFormValue(state, payload) {
      Object.assign(state.form, payload);
    },
    setIsEditingShipping(state, isEditingShipping) {
      state.isEditingShipping = isEditingShipping;
      if (!isEditingShipping) {
        if (state.sameAsBilling) {
          window.sessionStorage.removeItem('shipping');
        } else {
          window.sessionStorage.setItem('shipping', JSON.stringify(state.form));
        }
      }
    },
  },

  actions: {
    setSameAsBilling(context, payload) {
      context.commit('setSameAsBilling', payload);
    },
    setFormValue(context, payload) {
      context.commit('setFormValue', payload);
    },
    setFormFromCustomerData(context) {
      if (typeof context.rootState.customer.data.addrShipping !== 'undefined') {
        if (!Array.isArray(context.rootState.customer.data.addrShipping)) {
          const shipping = context.rootState.customer.data.addrShipping;

          const form = {
            id: shipping.id,
            name: shipping.name,
            city: shipping.city,
            street: shipping.street,
            zip: shipping.zip,
            // country is set elsewhere to avoid race condition
          };
          context.commit('setFormValue', form);
          context.commit('setSameAsBilling', false);
        }
      }
    },
    setCountryFromCustomerData(context) {
      let countryKey = null;
      if (context.rootState.countries.loaded) {
        if (typeof context.rootState.customer.data.addrShipping !== 'undefined') {
          if (!Array.isArray(context.rootState.customer.data.addrShipping)) {
            countryKey = context.rootState.customer.data.addrShipping.country;
          }
        }
        let index = null;
        context.rootState.countries.data.find(
          (country, i) => {
            if (country.key === countryKey) {
              index = i;
              return true;
            }
            return false;
          },
        );
        if (index !== null) {
          context.dispatch('setFormValue', {
            country: {
              index,
              name: context.rootState.countries.data[index].name,
              key: context.rootState.countries.data[index].key,
            },
          });
        }
      }
    },
    setIsEditingShipping(context, payload) {
      context.commit('setIsEditingShipping', payload);
    },
  },
  getters: {
    areShippingFieldsReady(state) {
      if (!state.isEditingShipping) {
        return true;
      }
      if (state.sameAsBilling) {
        return true;
      }
      if (state.form.name !== null
        && state.form.street !== null
        && state.form.city !== null
        && state.form.zip !== null
        && state.form.country.index !== null
        && state.form.country.name !== null
        && state.form.country.key !== null) {
        return true;
      }
      return false;
    },
  },
};
