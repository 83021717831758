export default {
  namespaced: true,
  state: {
    loaded: false,
    data: [],
  },
  mutations: {
    set(state, data) {
      Object.assign(state, { loaded: true });
      const newCountries = [];
      Object.keys(data).forEach((countryCode) => {
        newCountries.push({ name: data[countryCode], key: countryCode });
      });
      Object.assign(state, { data: newCountries });
    },
  },
  actions: {
    set(context, data) {
      return new Promise((resolve) => {
        context.commit('set', data);
        context.dispatch('user/setCountryFromCustomerData', null, { root: true });
        context.dispatch('user/setDefaultCountry', null, { root: true });
        context.dispatch('shipping/setCountryFromCustomerData', null, { root: true });
        resolve();
      });
    },
  },
};
