import md5 from 'blueimp-md5';
import request from '../request';
import errorHandler from '../lib/errorHandler';

export default {
  namespaced: true,
  state: {
    redirectionRoute: null,
    loaded: false,
    isLoading: false,
    isLoggedIn: false,
    loginFailed: false,
    recoverFailed: false,
    changePasswordFailed: false,
    isEditingBilling: false,
    data: {
      jwt: '',
    },
    requiredFields: [],
    visibleFields: [],
    form: {
      guid: '',
      email: '',
      oldPassword: '',
      password: '',
      salutation: {
        index: -1,
        name: undefined,
      },
      company: '',
      phone: '',
      firstName: '',
      lastName: '',
      emailConfirmation: '',
      passwordConfirmation: '',
      street: '',
      zip: '',
      city: '',
      country: {
        index: null,
        key: '',
        name: '',
      },
    },
    errors: {
    },
    emailErrors: {},
  },
  mutations: {
    setFormValue(state, payload) {
      Object.assign(state.form, payload);
    },
    setRedirectionRoute(state, payload) {
      state.redirectionRoute = payload;
    },
    clearForm(state) {
      Object.assign(state.form, {
        email: '',
        oldPassword: '',
        password: '',
        salutation: {
          index: -1,
          name: undefined,
        },
        company: '',
        firstName: '',
        lastName: '',
        emailConfirmation: '',
        passwordConfirmation: '',
        street: '',
        zip: '',
        city: '',
        country: {
          index: null,
          key: '',
          name: '',
        },
      });
    },
    setJWT(state, jwt) {
      state.data.jwt = jwt;
      Object.assign(state, { loaded: true });
    },
    setLoading(state, isLoading) {
      Object.assign(state, { isLoading });
    },
    setLoaded(state, loaded) {
      Object.assign(state, { loaded });
    },
    setLoggedIn(state, isLoggedIn) {
      Object.assign(state, { isLoggedIn });
    },
    setLoginFailed(state, loginFailed) {
      Object.assign(state, { loginFailed });
    },
    setRecoverFailed(state, recoverFailed) {
      Object.assign(state, { recoverFailed });
    },
    setChangePasswordFailed(state, changePasswordFailed) {
      Object.assign(state, { changePasswordFailed });
    },
    setIsEditingBilling(state, isEditingBilling) {
      Object.assign(state, { isEditingBilling });
    },
    setErrors(state, errors) {
      const newErrors = errorHandler.conformToFormat(errors);
      state.errors = newErrors;
    },
    setEmailErrors(state, errors) {
      const newErrors = errorHandler.conformToFormat(errors);
      state.emailErrors = newErrors;
    },
    clearPasswords(state) {
      Object.assign(state.form, { oldPassword: '' });
      Object.assign(state.form, { password: '' });
      Object.assign(state.form, { passwordConfirmation: '' });
    },
    setFormFromCustomerData(state, customer) {
      if (Object.keys(customer).length > 0) {
        const form = {
          // salutation is set elsewhere because it is more complex
          firstName: customer.fname,
          company: customer.company.name,
          email: customer.email,
          phone: customer.phone,
          lastName: customer.lname,
          street: customer.addrBilling.street,
          zip: customer.addrBilling.zip,
          city: customer.addrBilling.city,
          // country is set elsewhere to avoid race condition
        };
        Object.assign(state.form, form);
      }
    },
    setSalutationForm(state, salutation) {
      state.form.salutation = salutation;
    },
    setRequiredFields(state, fields) {
      state.requiredFields = fields;
    },
    setVisibleFields(state, fields) {
      state.visibleFields = fields;
    },
  },
  actions: {
    setSavedToken(context) {
      return new Promise((resolve) => {
        if (context.state.data.jwt !== '') {
          resolve();
        }
        const value = sessionStorage.getItem('jwt');
        if (value !== null) {
          context.commit('setJWT', value);
          context.commit('setLoggedIn', true);
          resolve();
        }
        resolve();
      });
    },
    setRedirectionRoute(context, payload) {
      context.commit('setRedirectionRoute', payload);
    },
    setFormValue(context, payload) {
      context.commit('setFormValue', payload);
    },
    login(context) {
      context.commit('setLoginFailed', false);
      const data = {
        _u: context.state.form.email,
        _p: md5(context.state.form.password),
      };
      window.vue.$toasted.show(window.vue.$t('AUTH_LOGGING_IN')).goAway(2000);
      context.commit('setLoading', true);
      const { app } = context.rootState;
      request.make({
        url: `${app.API_URL}${context.rootState.language}/${app.endpoints.LOGIN}`,
        type: 'POST',
        data,
      }, (err, res) => {
        context.commit('setLoaded', true);
        context.commit('setLoading', false);
        if (res.status === 200) {
          context.commit('setLoggedIn', true);
          context.commit('setJWT', res.body.data);
          context.dispatch('customer/get', res.body.data, { root: true });
          context.dispatch('payments/get', null, { root: true });
          if (context.state.redirectionRoute === null) {
            window.vue.$router.push({ name: 'home' });
          } else {
            window.vue.$router.push(context.state.redirectionRoute.fullPath);
          }
          context.dispatch('clearForm');
          sessionStorage.setItem('jwt', res.body.data);
        } else {
          window.vue.$toasted.show(window.vue.$t('AUTH_LOGIN_FAILED')).goAway(2000);
          context.commit('setLoginFailed', true);
        }
      });
    },
    getFormData(context) {
      return new Promise((resolve) => {
        const { app } = context.rootState;
        request.make({
          url: `${app.API_URL}${context.rootState.language}/${app.endpoints.USER_REGISTER_FORM_DATA}`,
          type: 'GET',
        }, (err, res) => {
          if (res.status === 200) {
            context.dispatch('countries/set', res.body.data.countries, { root: true }).then(() => {
              resolve();
            });
            context.dispatch('salutations/set', res.body.data.salutations, { root: true });
            if (typeof res.body.data.required_fields !== 'undefined') {
              context.commit('setRequiredFields', res.body.data.required_fields);
            }
            if (typeof res.body.data.visible_fields !== 'undefined') {
              context.commit('setVisibleFields', res.body.data.visible_fields);
            }
          }
        });
      });
    },
    setIsEditingBilling(context, payload) {
      context.commit('setIsEditingBilling', payload);
    },
    logout(context) {
      return new Promise((resolve) => {
        sessionStorage.removeItem('customer');
        sessionStorage.removeItem('jwt');
        context.commit('setLoggedIn', false);
        context.commit('setJWT', '');
        context.commit('clearForm');
        context.commit('customer/clear', null, { root: true });
        resolve();
      });
    },
    register(context, options) {
      return new Promise((resolve, reject) => {
        let validateOnly = false;
        let isGuest = false;
        if (typeof options !== 'undefined') {
          ({ validateOnly, isGuest } = options);
        }
        const { form } = context.state;
        const { app } = context.rootState;
        let salutation = form.salutation.name;
        if (typeof salutation === 'undefined') {
          salutation = null;
        }

        const data = {
          c: {
            email: form.email,
            password: form.password,
            salutation,
            fname: form.firstName,
            lname: form.lastName,
            company: form.company,
            phone: form.phone,
            street: form.street,
            zip: form.zip,
            city: form.city,
          },
        };
        if (context.state.visibleFields.includes('country')) {
          data.c.country = form.country.key;
        }
        if (!context.rootState.shipping.sameAsBilling) {
          data.c.shipping = {
            name: context.rootState.shipping.form.name,
            street: context.rootState.shipping.form.street,
            zip: context.rootState.shipping.form.zip,
            city: context.rootState.shipping.form.city,
            country: context.rootState.shipping.form.country.key,
          };
        }
        let url = `${app.API_URL}${context.rootState.language}/${context.rootState.app.endpoints.REGISTER}`;
        if (validateOnly) {
          url = `${app.API_URL}${context.rootState.language}/${context.rootState.app.endpoints.REGISTER_VALIDATE}`;
        }
        request.make({
          url,
          type: 'POST',
          data,
        }, (err, res) => {
          if (res.status === 422) {
            if ((!validateOnly) || isGuest) context.dispatch('showAllValidationErrors', true, { root: true });
            context.commit('setErrors', res.body.error);
            if (isGuest) {
              if (Object.keys(res.body.error).length === 1 && Object.keys(res.body.error)[0] === 'password') {
                resolve();
              }
              }else {
                reject();
              }
          } else if (res.status === 200) {
            context.dispatch('showAllValidationErrors', false, { root: true });
            context.commit('setErrors', {});
            resolve();
          }
        });
      });
    },
    registerGuest(context) {
      return new Promise((resolve) => {
        const { form } = context.state;
        const { app } = context.rootState;
        const data = {
          c: {
            email: form.email,
            phone: form.phone,
            salutation: form.salutation.name,
            fname: form.firstName,
            lname: form.lastName,
            company: form.company,
            street: form.street,
            zip: form.zip,
            city: form.city,
          },
        };

        if (form.country.key !== '') {
          data.c.country = form.country.key;
        }
        if (typeof context.rootState.cart.requestInvoice !== 'undefined') {
          data.c.send_invoice = context.rootState.cart.requestInvoice;
        }

        if (!context.rootState.shipping.sameAsBilling) {
          data.c.shipping = {
            name: context.rootState.shipping.form.name,
            street: context.rootState.shipping.form.street,
            zip: context.rootState.shipping.form.zip,
            city: context.rootState.shipping.form.city,
            country: context.rootState.shipping.form.country.key,
          };
        }
        const url = `${app.API_URL}${context.rootState.language}/${context.rootState.app.endpoints.REGISTER_GUEST}`;
        request.make({
          url,
          type: 'POST',
          data,
        }, (err, res) => {
          if (res.status === 422) {
            context.dispatch('showAllValidationErrors', true, { root: true });
            context.commit('setErrors', res.body.error);
          } else if (res.status === 200) {
            context.dispatch('showAllValidationErrors', false, { root: true });
            context.commit('setErrors', {});
            context.commit('setJWT', res.body.data);
            sessionStorage.setItem('jwt', res.body.data);

            context.dispatch('customer/setGuestCustomer', {
              salutation: form.salutation.name,
              fname: form.firstName,
              lname: form.lastName,
              email: form.email,
              phone: form.phone,
              company: {
                name: form.company,
              },
              street: form.street,
              zip: form.zip,
              city: form.city,
              country: form.country.key,
              addrBilling: {
                name: `${form.firstName} ${form.lastName}`,
                street: form.street,
                zip: form.zip,
                city: form.city,
                country: form.country.key,
              },
            }, { root: true }).then(() => {
              context.commit('setLoggedIn', true);
              resolve();
            });
          }
        });
      });
    },
    passwordReset(context) {
      const { app } = context.rootState;
      const url = `${app.API_URL}${context.rootState.language}/${context.rootState.app.endpoints.PASSWORD_RESET}`;
      const data = {
        email: context.state.form.email,
        backlink: `${window.location.origin}/${context.rootState.language}/recover/#`,
      };
      //window.vue.$toasted.show(window.vue.$t('AUTH_REQUESTING_RESET')).goAway(2000);
      context.commit('setRecoverFailed', false);
      request.make({
        url,
        type: 'POST',
        data,
      }, (err, res) => {
        if (res.status === 200) {
          context.commit('clearForm');
          window.vue.$router.push({ name: 'forgotPasswordSuccess' });
          window.vue.$toasted.show(window.vue.$t('AUTH_REQUEST_RESET_SUCCESS')).goAway(2000);
        } else {
          window.vue.$toasted.show(window.vue.$t('AUTH_REQUEST_RESET_FAIL')).goAway(2000);
          context.commit('setRecoverFailed', true);
        }
      });
    },
    passwordConfirm(context) {
      context.dispatch('showAllValidationErrors', false, { root: true });
      const { app } = context.rootState;
      const url = `${app.API_URL}${context.rootState.language}/${context.rootState.app.endpoints.PASSWORD_CONFIRM}`;
      const data = {
        token: context.state.form.guid,
        _p: context.state.form.password,
      };
      window.vue.$toasted.show(window.vue.$t('AUTH_SETTING_NEW_PASSWORD')).goAway(2000);
      request.make({
        url,
        type: 'POST',
        data,
      }, (err, res) => {
        if (res.status === 422) {
          context.dispatch('showAllValidationErrors', true, { root: true });
          context.commit('setErrors', { error: res.body.error });
          window.vue.$toasted.show(window.vue.$t('AUTH_SETTING_NEW_PASSWORD_FAILED')).goAway(2000);
        } else if (res.status === 400) {
          context.dispatch('showAllValidationErrors', true, { root: true });
          context.commit('setErrors', { error: 'You must fill all fields' });
          window.vue.$toasted.show(window.vue.$t('AUTH_SETTING_NEW_PASSWORD_FAILED')).goAway(2000);
        } else if (res.status === 200) {
          window.vue.$toasted.show(window.vue.$t('AUTH_SETTING_NEW_PASSWORD_SUCCESS')).goAway(2000);
          context.commit('setLoggedIn', true);
          context.commit('setJWT', res.body.data);
          context.dispatch('customer/get', res.body.data, { root: true });
          sessionStorage.setItem('jwt', res.body.data);

          window.vue.$router.push({ name: 'recoverPasswordSuccess' });
        }
      });
    },
    setPassword(context) {
      context.commit('setChangePasswordFailed', false);
      context.commit('setErrors', {});
      const data = {
        _po: context.state.form.oldPassword,
        _pn: context.state.form.password,
      };
      const type = 'POST';
      const { app } = context.rootState;
      const url = `${app.API_URL}${context.rootState.language}/${context.rootState.app.endpoints.PASSWORD_CHANGE}?_=${context.state.data.jwt}`;

      window.vue.$toasted.show(window.vue.$t('AUTH_PASSWORD_CHANGE_REQUEST')).goAway(2000);

      request.make({
        url, type, data,
      }, (err, res) => {
        if (res.status === 200) {
          window.vue.$router.push({ name: 'home' });
          window.vue.$toasted.show(window.vue.$t('AUTH_PASSWORD_CHANGE_SUCCESS')).goAway(2000);
          context.commit('setErrors', {});
          context.commit('clearForm');
        } else {
          context.commit('setErrors', res.body.error);
          context.commit('setChangePasswordFailed', true);
          window.vue.$toasted.show(window.vue.$t('AUTH_PASSWORD_CHANGE_FAILED')).goAway(2000);
        }
      });
    },
    setEmail(context) {
      context.commit('setEmailErrors', {});
      const data = {
        _po: context.state.form.oldPassword,
        email: context.state.form.email,
      };
      const type = 'POST';
      const { app } = context.rootState;
      const url = `${app.API_URL}${context.rootState.language}/${context.rootState.app.endpoints.EMAIL_CHANGE}?_=${context.state.data.jwt}`;
      request.make(
        { url, type, data },
        (err, res) => {
          if (res.status === 422) {
            context.commit('setEmailErrors', res.body.error);
          } else if (res.status === 200) {
            window.vue.$toasted.show(window.vue.$t('AUTH_EMAIL_CHANGE_SUCCESS')).goAway(2000);
          }
        },
      );
    },
    update(context, payload) {
      return new Promise((resolve) => {
        const { app } = context.rootState;
        let url = `${app.API_URL}${context.rootState.language}/${context.rootState.app.endpoints.CUSTOMER_UPDATE}`;
        let validateOnly = false;
        if (typeof payload !== 'undefined') {
          if (payload.validateOnly) {
            validateOnly = true;
          }
        }
        if (validateOnly) {
          url = `${app.API_URL}${context.rootState.language}/${context.rootState.app.endpoints.CUSTOMER_VALIDATE}`;
        }
        if (context.state.data.jwt !== '') {
          url = `${url}?_=${context.state.data.jwt}`;
        }
        const type = 'POST';

        let salutation = context.state.form.salutation.name;
        if (typeof salutation === 'undefined') {
          salutation = null;
        }
        const data = {
          c: {
            salutation,
            company: context.state.form.company,
            email: context.state.form.email,
            phone: context.state.form.phone,
            fname: context.state.form.firstName,
            lname: context.state.form.lastName,
            street: context.state.form.street,
            zip: context.state.form.zip,
            city: context.state.form.city,
            country: context.state.form.country.key,
          },
        };
        if (!context.rootState.shipping.sameAsBilling) {
          data.c.shipping = {
            name: context.rootState.shipping.form.name,
            street: context.rootState.shipping.form.street,
            zip: context.rootState.shipping.form.zip,
            city: context.rootState.shipping.form.city,
            country: context.rootState.shipping.form.country.key,
          };
        }
        request.make({
          url, type, data,
        }, (err, res) => {
          if (res.status === 422) {
            if (!validateOnly) context.dispatch('showAllValidationErrors', true, { root: true });
            context.commit('setErrors', res.body.error);
          } else if (res.status === 200) {
            if (!validateOnly) {
              window.vue.$toasted.show(window.vue.$t('USER_SETTINGS_SAVED')).goAway(2000);
            }
            context.commit('setErrors', []);
            if (!validateOnly) {
              context.dispatch('customer/get', context.state.data.jwt, { root: true });
            }
            resolve();
          }
        });
      });
    },
    clearForm(context) {
      context.commit('clearForm');
      context.dispatch('showAllValidationErrors', false, { root: true });
    },
    setFormFromCustomerData(context) {
      return new Promise((resolve) => {
        context.commit('setFormFromCustomerData', context.rootState.customer.data);
        if (context.rootState.salutations.loaded) {
          context.dispatch('setSalutationFromCustomerData');
        }
        if (context.rootState.countries.loaded) {
          context.dispatch('setCountryFromCustomerData');
        }
        resolve();
      });
    },
    setSalutationFromCustomerData(context) {
      return new Promise((resolve) => {
        if (context.rootState.salutations.loaded) {
          const name = context.rootState.customer.data.salutation;
          let index = context.rootState.salutations.data.findIndex(
            (salutation) => salutation.name === context.rootState.customer.data.salutation,
          );
          if (index === -1) {
            index = -1;
          }
          context.commit('setSalutationForm', { index, name });
        }
        resolve();
      });
    },
    setCountryFromCustomerData(context) {
      let countryKey = null;
      if (context.rootState.countries.loaded) {
        if (typeof context.rootState.customer.data.addrBilling !== 'undefined') {
          countryKey = context.rootState.customer.data.addrBilling.country;
        }
        let index = null;
        context.rootState.countries.data.find(
          (country, i) => {
            if (country.key === countryKey) {
              index = i;
              return true;
            }
            return false;
          },
        );
        if (index !== null) {
          context.dispatch('setFormValue', {
            country: {
              index,
              name: context.rootState.countries.data[index].name,
              key: context.rootState.countries.data[index].key,
            },
          });
        }
      }
    },
    clearPasswords(context) {
      context.commit('clearPasswords');
    },
    setDefaultCountry(context) {
      if (context.state.visibleFields.includes('country')) {
        const defaultCountryKey = context.rootState.app.defaultUserCountryKey;
        if (context.state.form.country.index === null) {
          if (typeof defaultCountryKey !== 'undefined') {
            if (defaultCountryKey !== null && defaultCountryKey !== '') {
              const index = context.rootState.countries.data.findIndex(
                (country) => defaultCountryKey === country.key,
              );
              if (index) {
                context.commit('setFormValue', {
                  country: {
                    index,
                    key: context.rootState.countries.data[index].key,
                    name: context.rootState.countries.data[index].name,
                  },
                });
              }
            }
          }
        }
      }
    },
  },
};
