<template>
  <div id="waitingForServer">
    <div v-if="this.$store.state.waitingForServer.waiting">
      <Icon icon="circleNotch"></Icon>
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: 'WaitingForServer',
  components: {
    Icon,
  },
  data() {
    return {
    };
  },
  methods: {
    dismiss() {
      this.$store.dispatch('unsetGlobalMessage');
    },
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
#waitingForServer {
  position: fixed;
  color: white;
  z-index: 1001;
  right: 10px;
  top: 15px;
  i {
    text-shadow: 0 0 2px #00695C;
  }
}
</style>
