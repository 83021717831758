const baseState = {
  lastVisitedUrl: sessionStorage.getItem('lastVisitedUrl'),
};

export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(baseState)),
  mutations: {
    setLastVisitedUrl(state, lastVisitedUrl) {
      Object.assign(state, { lastVisitedUrl });
    },
  },
  actions: {
    setLastVisitedUrl(context, url) {
      return new Promise((resolve) => {
        context.commit('setLastVisitedUrl', url);
        sessionStorage.setItem('lastVisitedUrl', url);
        resolve();
      });
    },
  },
};
