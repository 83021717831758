
export default {
  namespaced: true,
  state: {
    showDirectDebit: false,
    form: {
      accountHolder: '',
      IBAN: '',
    },
  },
  mutations: {
    setDirectDebitVisibility(state, v) {
      state.showDirectDebit = v;
    },
    setFormValue(state, { field, value }) {
      state.form[field] = value;
    },
  },
  actions: {
    setDirectDebitVisibility(context, v) {
      context.commit('setDirectDebitVisibility', v);
    },
    setFormValue(context, { field, value }) {
      context.commit('setFormValue', { field, value });
    },
  },
};
