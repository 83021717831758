export default {
  state: {
    waiting: false,
  },
  mutations: {
    setWaiting(state, val) {
      Object.assign(state, { waiting: val });
    },
  },
  actions: {
    setWaitingForServer(context, payload) {
      context.commit('setWaiting', payload);
    },
  },
};
