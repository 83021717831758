<template>
  <div class="globalMessage" v-bind:class="className"
    v-if="this.$store.state.globalMessage.message.text">
    <p class="white-text">{{this.$store.state.globalMessage.message.text}}</p>
    <button class="dismissBtn" v-on:click="dismiss">
      <Icon icon="close"></Icon>
    </button>
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: 'GlobalMessage',
  components: {
    Icon,
  },
  data() {
    return {
    };
  },
  methods: {
    dismiss() {
      this.$store.dispatch('globalMessage/unset');
    },
  },
  computed: {
    className() {
      if (this.$store.state.globalMessage.message.className !== false) {
        return `${this.$store.state.globalMessage.message.className}`;
      }
      return '';
    },
  },
  watch: {
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
  .globalMessage {
    position: fixed;
    margin-left: 10vw;
    margin-right: 10vw;
    width: 80vw;
    z-index: 9001;
    background: white;
    box-shadow: 0 2px 4px 0 #AAADAC;
    margin-top: $sp * 5;
    margin-bottom: $sp * 5;
    padding: 4*$sp;
  }
  /*.card {
    position: fixed;
    bottom: 0;
    padding-right: 50px;
    z-index: 1000;
    margin-bottom: 0;
  }*/
  .dismissBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: 0;
    background: transparent;
    cursor: pointer;
    box-shadow: none;
    color: black;
  }

  .globalMessage.danger{
    color: white;
    background-color: $brand-danger;
  }
  .globalMessage.warning {
    color: white;
    background-color: $brand-warning;
  }
  .globalMessage.info {
    color: white;
    background-color: $brand-info;

  }
  .globalMessage.success {
    color: white;
    background-color: $brand-success;
  }
  .globalMessage.danger, .globalMessage.warning .globalMessage.info, .globalMessage.success {
    .dismissBtn {
      color: white;
    }
  }

</style>
